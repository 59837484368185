import { useTranslation } from "react-i18next";
import { Tab, Tabs } from "@material-ui/core";
import { useEffect, useState } from "react";
import moment from "moment";
import * as Sentry from "@sentry/browser";
import { toast } from "material-react-toastify";
import { isEmpty, prependZero } from "../../utilities/help";
import {
  get_phone_otp,
  sendEmailOtp,
  verifyEmailOtp,
  verify_phone_otp,
} from "../../store/features/Auth/Auth";
import { useAppSelector } from "../../store/hooks";
import FormErrorText from "../FormErrorText";
import { sendResendCode } from "../../store/features/Transfer/Transfer";
import CustomButton from "../CustomButton";

const WithdrawConfirmation = (props: any) => {
  const {
    onResend = () => {},
    onSubmit = () => {},
    setpaymentActiveStep,
    loading,
    cashoutResponse,
  } = props;
  const { t } = useTranslation();
  const {
    auth: { user },
  } = useAppSelector((state) => state.persistedReducer);

  const [method, setMethod] = useState(0);
  const handleMethodChange = (
    event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    setMethod(newValue);
  };
  const [otp, setotp] = useState("");

  // resend logic
  const [disableResend, setdisableResend] = useState(false);
  const [seconds, setseconds] = useState(30);
  const [isTimerRunning, setisTimerRunning] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 1) {
        isTimerRunning && setseconds(seconds - 1);
      }

      if (seconds === 1) {
        setdisableResend(false);
        setisTimerRunning(false);
        clearInterval(interval);
        isTimerRunning && setseconds(30);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  const handleOTPAbuse = (successCallback: any) => {
    try {
      if (window.localStorage.getItem("otpresendtime") == undefined) {
        const payload = { times: 1, time: moment() };
        window.localStorage.setItem("otpresendtime", JSON.stringify(payload));
        successCallback();
      } else {
        const oldPayload = JSON.parse(
          window.localStorage.getItem("otpresendtime")!
        );

        const isTimeLessThanTwoMin =
          moment.duration(moment().diff(moment(oldPayload.time))).minutes() < 2;

        if (parseInt(oldPayload.times) >= 5 && isTimeLessThanTwoMin) {
          toast.error(t("otp_limit_error"));
          setTimeout(() => {
            window.localStorage.removeItem("otpresendtime");
          }, 2 * 60 * 1000);
        } else {
          const payload = { ...oldPayload, times: oldPayload.times + 1 };
          window.localStorage.setItem("otpresendtime", JSON.stringify(payload));
          successCallback();
        }
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  const startTimer = () => {
    const successCallback = () => {
      if (!disableResend) {
        setisTimerRunning(true);
        setdisableResend(true);
        onResend && onResend();
        setseconds(30);
      }
    };
    handleOTPAbuse(successCallback);
  };

  const sendOTP = () => {
    try {
      sendResendCode(
        { channel: method == 0 ? "sms" : method == 1 ? "whatsapp" : "mail" },
        cashoutResponse?.cashout.id
      ).catch((error: any) => {
        Sentry.captureException(error);
        toast.error(
          t(error?.data?.error) ||
            t(error?.data?.message) ||
            t("otp_failed_default_error")
        );
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const [otpVerificationInProgress, setotpVerificationInProgress] =
    useState(false);
  const validateOTP = () => {
    try {
      onSubmit(otp);
      setotp("");
      //       setotpVerificationInProgress(false);
    } catch (error: any) {
      toast.error(t(error));
      setotpVerificationInProgress(false);
      Sentry.captureException(error);
    }
  };

  return (
    <div
      className="col-xl-6 col-lg-7 col-md-10 col-12 my-12 mx-auto shadow-lg max-sm:!shadow-md px-4 py-7 max-sm:!px-2 rounded-lg"
      id="transferPaymentWithdrawContainer"
    >
      <div className="font-bold text-2xl text-center">
        {t("verification_code")}
      </div>
      <div className="text-center opacity-75 mb-7">
        {t("verification_choose_method")}
      </div>

      <Tabs
        value={method}
        classes={{
          flexContainer: "flex-wrap sm:flex-nowrap",
        }}
        TabIndicatorProps={{
          className: "block max-[370px]:hidden",
          style: {
            backgroundColor: "#037375",
            minWidth: "10px",
          },
        }}
        onChange={handleMethodChange}
        variant="fullWidth"
        className="max-sm:!px-0 mb-10"
      >
        <Tab
          style={{
            textTransform: "none",
            minWidth: 60,
            borderBottom: method == 0 ? "2px solid #037375" : "none",
            color: method == 0 ? "#037375" : "rgba(0,0,0,0.75)",
            fontWeight: method == 0 ? 700 : 400,
          }}
          label={<div className="md:text-base text-sm">{t("sms")}</div>}
        />
        <Tab
          style={{
            textTransform: "none",
            minWidth: 60,
            borderBottom: method == 1 ? "2px solid #037375" : "none",
            color: method == 1 ? "#037375" : "rgba(0,0,0,0.75)",
            fontWeight: method == 1 ? 700 : 400,
          }}
          label={t("whatsapp")}
          className="md:text-base text-sm"
        />
        <Tab
          style={{
            textTransform: "none",
            minWidth: 60,
            borderBottom: method == 2 ? "2px solid #037375" : "none",
            color: method == 2 ? "#037375" : "rgba(0,0,0,0.75)",
            fontWeight: method == 2 ? 700 : 400,
          }}
          label={t("Email")}
          className="md:text-base text-sm"
        />
      </Tabs>

      <div
        className={
          disableResend
            ? "text-right opcaity-75 cursor-pointer"
            : "text-right text-[#038385] font-bold cursor-pointer"
        }
        onClick={() => {
          if (!disableResend) {
            startTimer();
            sendOTP();
          }
        }}
      >
        {disableResend ? (
          <>
            <>{t("resend_withdraw_code_1")}</>
            <span className="text-[#035357] font-bold">
              00:{prependZero(seconds)}
            </span>
            <>{t("resend_withdraw_code_2")}</>
          </>
        ) : (
          t("click_receive_code")
        )}
      </div>

      <div className="mb-9">
        <input
          className="flex-1 w-full h-[50px] border border-1-[rgba(0,0,0,0.2)] !p-4 !text-[rgba(0,0,0,0.5)] rounded-lg"
          placeholder={t("enter_code_received")}
          onChange={(e: any) => setotp(e.target.value)}
          value={otp}
          type="string"
          onKeyDown={(e) => {
            if (["-", "+", "e"].includes(e.key)) {
              e.preventDefault();
            }
          }}
        />
        {/^(\d){6}$/.test(otp) == false && otp !== "" && (
          <FormErrorText errorMessage={t("otp_invalid")} />
        )}
      </div>

      <div className="flex max-sm:flex-col justify-between gap-2 mx-0 px-0">
        <CustomButton
          label="back"
          classNames="min-h-[48px] w-1/3 rounded-xl text-white capitalize text-sm font-bold bg-[#9E9E9E]"
          disabled={otpVerificationInProgress || loading}
          onClick={() => {
            setpaymentActiveStep(0);
          }}
        />
        <CustomButton
          classNames="min-h-[48px] w-2/3 rounded-xl text-white capitalize text-sm font-bold bg-black"
          label="submit_code"
          disabled={
            /^(\d){6}$/.test(otp) == false ||
            otpVerificationInProgress ||
            loading
          }
          onClick={validateOTP}
        />
      </div>
    </div>
  );
};

export default WithdrawConfirmation;
