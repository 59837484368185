import * as React from "react";
import Box from "@material-ui/core/Box";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/browser";
import "../../helpers/i18n";
import {
  initiateCashOut,
  confirmCashOut,
  currentBalance,
  getAppliedFeesForWithdraws,
  getUsersMFIAccounts,
} from "../../store/features/Transfer/Transfer";
import { toast } from "material-react-toastify";
import { extractError } from "../../utility";
import { useHistory } from "react-router";
import {
  getGoogleSheetData,
  translateError,
  useDebounce,
} from "../../utilities/help";
import { clearSelectedBank } from "../../store/features/Auth/AuthSlice";
import {
  openWithdraw,
  setWithDrawData,
} from "../../store/features/Transfer/TransferSlice";
import useGoogleSheets from "use-google-sheets";
import WithdrawDetails from "../../components/Withdraws/WithdrawDetails";
import WithdrawConfirmation from "../../components/Withdraws/WithdrawConfirmation";
import WithdrawSuccessScreen from "../../components/Withdraws/WithdrawSuccessScreen";
import { LinearProgress } from "@material-ui/core";
import SignUpProgressBar from "../../components/SignUpProgressBar";
import WithdrawPaymentModal from "../../components/Withdraws/WithdrawPaymentModal";
import WithdrawVerificationModal from "../../components/Withdraws/WithdrawVerificationModal";

const RATE = 655.957;

const Cashout: React.FC<any> = ({ setWithDrawOpen }) => {
  const { t } = useTranslation();
  const {
    auth: { user, selected_account },
    transfer: { loggedInUserCountry },
  } = useAppSelector((state) => state.persistedReducer);
  const dispatch = useAppDispatch();

  const [steps, setSteps] = React.useState([
    t("amount"),
    t("PaymentMode"),
    t("verification"),
    t("Confirmation"),
  ]);

  const {
    auth: { currency, rate },
    transfer,
  } = useAppSelector((state) => state.persistedReducer);
  const [toWithDraw, setToWithDraw] = React.useState<any>(
    transfer?.withdraw?.toWithDraw || 0
  );
  const [loading, setLoading] = React.useState(false);
  const [otp, setOTP] = React.useState(0);
  const [fees, setFees] = React.useState(0);
  const [type, setType] = React.useState("");
  const [activeStep, setActiveStep] = React.useState(0);
  const [error, setError] = React.useState(null);
  const [cashoutResponse, setCashoutResponse] = React.useState<any>(null);
  const [cashoutMethod, setCashoutMethod] = React.useState<any[]>([]);
  const [method, setMethod] = React.useState<any>(null);

  const [euro_balance, setEuroBalance] = React.useState<any>(0);
  const [size, setSize] = React.useState<any>([0, 0]);
  const [repeatWithDraw, setRepeatWithDraw] = React.useState<boolean>(false);
  const [c_channel, setCommunicationChannel] = React.useState(
    transfer?.loggedInUserCountry?.preferred_notification_channel ?? "mail"
  );
  const history = useHistory();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handlePrev = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const showToast = (message: string, type = "error") => {
    toast.error(t(message));
  };

  const setTypeFromPaymentType = (type: any) => {
    setRepeatWithDraw(false);
    if (type === "manual_bank_transfer" || type === "bank_transfer") {
      setType("bank");
    } else if (type === "mobile_money") {
      setType("mobile");
    } else if (type === "mfi") {
      setType("mfi");
    }
  };

  // used for withdraw repeat
  const getMethods = async (id?: any) => {
    try {
      const methods = transfer.loggedInUserCountry.cashout_methods;

      // show all providers for dp-1119
      // set bank payout as default method
      if (methods.length > 0) {
        const isCashoutPresent = methods.find(
          (method: any) => method?.cashout_method?.id == id
        );

        if (id !== undefined && id !== null) {
          setMethod(id);
        } else {
          //  set first option as default selected
          setCashoutMethod(
            methods.map((itm: any, itmInd: any) =>
              itmInd == 0
                ? { ...itm, isChecked: true }
                : { ...itm, isChecked: false }
            )
          );
          const firstMethod = methods[0];
          setTypeFromPaymentType(
            firstMethod?.cashout_method?.payment_type?.name
          );
          setMethod(firstMethod?.cashout_method?.id);
          dispatch(clearSelectedBank());
        }

        // if (id !== undefined && id !== null && isCashoutPresent !== undefined) {
        //   // set provider selection with option selected
        //   setCashoutMethod(
        //     methods.map((itm: any) =>
        //       itm?.cashout_method?.id == id
        //         ? { ...itm, isChecked: true }
        //         : { ...itm, isChecked: false }
        //     )
        //   );
        //   // set cashin in method id
        //   setMethod(id);
        // } else {
        //   // set first option as default selected
        //   setCashoutMethod(
        //     methods.map((itm: any, itmInd: any) =>
        //       itmInd == 0
        //         ? { ...itm, isChecked: true }
        //         : { ...itm, isChecked: false }
        //     )
        //   );
        //   const firstMethod = methods[0];
        //   setTypeFromPaymentType(
        //     firstMethod?.cashout_method?.payment_type?.name
        //   );
        //   setMethod(firstMethod?.cashout_method?.id);
        //   dispatch(clearSelectedBank());
        // }
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  // on submit
  const startCashOut = () => {
    try {
      setError(null);
      if (method === "") {
        showToast(t("select_cashOut"));
        return;
      }
      if (selected_account === null && type !== "delivery") {
        showToast(t("select_account"));
        return;
      }
      if (parseFloat(euro_balance) <= 0) {
        showToast(t("balance_zero"));
        return;
      }
      const amount_in_euros =
        currency === "CFA" ? toWithDraw / rate : toWithDraw;
      const local_amount = currency === "CFA" ? toWithDraw : toWithDraw * RATE;

      if (amount_in_euros > parseFloat(euro_balance)) {
        showToast(t("Withdrawal_amount"));
        return;
      }

      setLoading(true);
      let finalpayload = {};
      if (type == "mfi") {
        finalpayload = {
          amount_without_fees_in_euro: Number(amount_in_euros),
          cashout_method_id: Number(method),
          mfi_account_id: selected_account.id,
          mobile_money_account_id: null,
          local_amount,
          local_currency: "CFA",
          country_id: loggedInUserCountry.id,
        };
      } else if (type == "bank") {
        finalpayload = {
          amount_without_fees_in_euro: Number(amount_in_euros),
          cashout_method_id: Number(method),
          bank_account_id: selected_account.id,
          mobile_money_account_id: null,
          local_amount,
          local_currency: "CFA",
          country_id: loggedInUserCountry.id,
        };
      } else if (type == "mobile") {
        finalpayload = {
          amount_without_fees_in_euro: Number(amount_in_euros),
          cashout_method_id: Number(method),
          bank_account_id: null,
          mobile_money_account_id: selected_account.id,
          local_amount,
          local_currency: "CFA",
          country_id: loggedInUserCountry.id,
        };
      } else if (type == "delivery") {
        finalpayload = {
          amount_without_fees_in_euro: Number(amount_in_euros),
          cashout_method_id: Number(method),
          local_amount,
          local_currency: "CFA",
          country_id: loggedInUserCountry.id,
          delivery_receiver_id: user?.id,
        };
      }

      initiateCashOut(finalpayload)
        .then((response: any) => {
          setCashoutResponse(response);
          setLoading(false);
          handleNext();
          window.heap.track("Account to withdraw details", {
            email: user.email,
            country: user.country,
            phone: user.full_phone_number,
            name: user.full_name,
          });
        })
        .catch((error: any) => {
          setLoading(false);
          toast.error(translateError(extractError(error), t));

          Sentry.captureException(error);
        });
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  const [cashoutID, setcashoutID] = React.useState("");
  const [cashoutType, setCashoutType] = React.useState("");

  const newCashoutSubmission = (id?: any, type?: any) => {
    try {
      try {
        setcashoutID(id);
        setCashoutType(type);
        setError(null);

        if (method === "") {
          showToast(t("select_cashOut"));
          return;
        }

        if (parseFloat(euro_balance) <= 0) {
          showToast(t("balance_zero"));
          return;
        }

        const amount_in_euros =
          currency === "CFA" ? toWithDraw / rate : toWithDraw;
        const local_amount =
          currency === "CFA" ? toWithDraw : toWithDraw * RATE;

        if (amount_in_euros > parseFloat(euro_balance)) {
          showToast(t("Withdrawal_amount"));
          return;
        }

        setLoading(true);
        let finalpayload = {};
        if (type == "mfi") {
          finalpayload = {
            amount_without_fees_in_euro: Number(amount_in_euros),
            cashout_method_id: Number(method),
            mfi_account_id: id,
            mobile_money_account_id: null,
            local_amount,
            local_currency: "CFA",
            country_id: loggedInUserCountry.id,
          };
        } else if (type == "bank") {
          finalpayload = {
            amount_without_fees_in_euro: Number(amount_in_euros),
            cashout_method_id: Number(method),
            bank_account_id: id,
            mobile_money_account_id: null,
            local_amount,
            local_currency: "CFA",
            country_id: loggedInUserCountry.id,
          };
        } else if (type == "mobile") {
          finalpayload = {
            amount_without_fees_in_euro: Number(amount_in_euros),
            cashout_method_id: Number(method),
            bank_account_id: null,
            mobile_money_account_id: id,
            local_amount,
            local_currency: "CFA",
            country_id: loggedInUserCountry.id,
          };
        } else if (type == "delivery") {
          finalpayload = {
            amount_without_fees_in_euro: Number(amount_in_euros),
            cashout_method_id: Number(method),
            local_amount,
            local_currency: "CFA",
            country_id: loggedInUserCountry.id,
            delivery_receiver_id: user?.id,
          };
        }

        initiateCashOut(finalpayload)
          .then((response: any) => {
            setCashoutResponse(response);
            setLoading(false);
            setActiveStep(1);
            window.heap.track("Account to withdraw details", {
              email: user.email,
              country: user.country,
              phone: user.full_phone_number,
              name: user.full_name,
            });
          })
          .catch((error: any) => {
            setLoading(false);
            toast.error(translateError(extractError(error), t));

            Sentry.captureException(error);
          });
      } catch (error: any) {
        Sentry.captureException(error);
      }
      // }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const confirmOTP = (otpCode: any) => {
    try {
      setLoading(true);
      confirmCashOut({
        key_code: otpCode,
        id: cashoutResponse?.cashout.id,
      })
        .then(() => {
          setLoading(false);
          handleNext();
          window.heap.track("Withdraw confirmation", {
            email: user.email,
            country: user.country,
            phone: user.full_phone_number,
            name: user.full_name,
          });
        })
        .catch((error: any) => {
          setLoading(false);
          toast.error(translateError(extractError(error), t));

          Sentry.captureException(error);
        });
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  const [directPaymentAmountExceed, setdirectPaymentAmountExceed] =
    React.useState(false);

  const calculatedFees = () => {
    if (toWithDraw < 1) {
      return;
    }
    try {
      setLoading(true);
      getAppliedFeesForWithdraws(
        {
          euro_amount: currency === "EUR" ? toWithDraw : toWithDraw / RATE,
          country_id: loggedInUserCountry?.id,
          cashout_method_id: method,
        },
        user?.id
      )
        .then((response: any) => {
          setFees(response.fee);
          setdirectPaymentAmountExceed(false);
        })
        .catch((error: any) => {
          toast.warning(t(error?.data?.message));
          Sentry.captureException(error);
          if (
            error &&
            (error?.data?.message == "Amount out of range" ||
              error?.data?.message == "Amount above maximum range" ||
              error?.data?.message == "Amount below minimum range")
          ) {
            setdirectPaymentAmountExceed(true);
          }
        })
        .finally(() => setLoading(false));
    } catch (error: any) {
      setLoading(false);
      Sentry.captureException(error);
    }
  };

  const renderSection = () => {
    if (activeStep === 0) {
      return (
        <WithdrawDetails
          loading={loading}
          toWithDraw={toWithDraw}
          setToWithDraw={setToWithDraw}
          setMethod={setMethod}
          cashoutMethod={cashoutMethod}
          directPaymentAmountExceed={directPaymentAmountExceed}
          newCashoutSubmission={newCashoutSubmission}
          method={method}
          isUserRestricted={isUserRestricted}
          checkingForRestriction={checkingForRestriction}
        />
      );
    }

    if (activeStep === 1) {
      return (
        <WithdrawConfirmation
          loading={loading}
          cashoutResponse={cashoutResponse}
          onResend={() => {}}
          onSubmit={(otpCode: any) => {
            confirmOTP(otpCode);
          }}
          setpaymentActiveStep={setActiveStep}
        />
      );
    }

    if (activeStep === 2) {
      return (
        <WithdrawSuccessScreen
          method={method}
          cashoutResponse={cashoutResponse}
        />
      );
    }
  };

  const getBalance = async () => {
    try {
      const balance: any = await currentBalance();
      const bal = balance?.client.euro_balance;
      setEuroBalance(bal);
    } catch (error: any) {
      toast.error(t(extractError(error)));
    }
  };

  React.useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  React.useEffect(() => {
    getBalance();
    getMethods();
    getUsersMFIAccounts();
  }, []);

  useDebounce(
    () => {
      if (method !== null) {
        calculatedFees();
      }
    },
    [toWithDraw],
    1000
  );

  React.useEffect(() => {
    if (method !== null) {
      setdirectPaymentAmountExceed(false);
      calculatedFees();
    }
  }, [method]);

  // check if user is restriced
  const [isUserRestricted, setisUserRestricted] = React.useState(false);
  const [checkingForRestriction, setcheckingForRestriction] =
    React.useState(false);

  // setter for bank with methodid
  const findAndSetBank = () => {
    try {
      if (transfer?.loggedInUserCountry?.cashout_methods !== undefined) {
        const cashoutMethods = transfer.loggedInUserCountry.cashout_methods;
        const banktransferMethod =
          cashoutMethods &&
          cashoutMethods.find(
            (methodItm: any) =>
              methodItm?.cashout_method?.payment_type?.name ==
                "bank_transfer" ||
              methodItm?.cashout_method?.payment_type?.name ==
                "manual_bank_transfer"
          );
        setType("bank");
        setRepeatWithDraw(false);

        banktransferMethod && setMethod(banktransferMethod?.cashout_method?.id);
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  const {
    data: googleSheetData,
    loading: googleSheetsLoading,
    error: googleSheetError,
  } = useGoogleSheets({
    apiKey: process.env.REACT_APP_GOOGLE_SHEET_API_ID!,
    sheetId: process.env.REACT_APP_GOOGLE_SHEET_ID!,
  });

  React.useEffect(() => {
    if (googleSheetError) {
      Sentry.captureException(googleSheetError);
    }
  }, [googleSheetError]);

  const handleUserRestriction = (handleRepeatCallback?: any) => {
    // dont run on closed window
    if (transfer?.isWithdrawOpen !== true || googleSheetsLoading) {
      return;
    }
    try {
      setcheckingForRestriction(true);

      let restrictedEmails: any = getGoogleSheetData(googleSheetData);
      if (restrictedEmails == undefined) {
        setcheckingForRestriction(false);
        return;
      }
      if (restrictedEmails.includes(user?.email)) {
        setisUserRestricted(true);

        // reset selected account in case of non bank method
        if (
          !(
            transfer?.withdraw?.withDrawMethod === "bank_transfer" ||
            transfer?.withdraw?.withDrawMethod === "manual_bank_transfer"
          )
        ) {
          dispatch(clearSelectedBank());
        }

        // set type as bank, repeat false, set method id
        findAndSetBank();
        setcheckingForRestriction(false);
      } else {
        setisUserRestricted(false);
        setcheckingForRestriction(false);
        handleRepeatCallback && handleRepeatCallback();
      }
    } catch (error: any) {
      Sentry.captureException(error);
      setcheckingForRestriction(false);
      handleRepeatCallback && handleRepeatCallback();
    }
  };

  // on repeat set type, repeatwithdraw true and send the cashout method id
  const handleRepeatOperation = () => {
    getMethods(transfer?.withdraw?.data?.retry_payload?.cashout_method_id);
    if (transfer?.withdraw?.withDrawMethod === "mobile_money") {
      setRepeatWithDraw(true);
      setType("mobile");
    } else if (
      transfer?.withdraw?.withDrawMethod === "manual_bank_transfer" ||
      transfer?.withdraw?.withDrawMethod === "bank_transfer"
    ) {
      setRepeatWithDraw(true);
      setType("bank");
    } else if (transfer?.withdraw?.withDrawMethod === "mfi") {
      setRepeatWithDraw(true);
      setType("mfi");
    } else if (transfer?.withdraw?.withDrawMethod === "delivery") {
      setRepeatWithDraw(true);
      setType("delivery");
    }
  };

  // repeat operation and handling restriction
  React.useEffect(() => {
    // set cashout method id
    // set type
    // set repeat on
    // also check for restriction
    if (transfer?.withdraw?.withDrawMethod !== "") {
      handleUserRestriction(handleRepeatOperation());
    } else {
      handleUserRestriction();
    }
  }, [transfer?.withdraw?.withDrawMethod, googleSheetsLoading]);

  // handle progress bar data

  const progressBarData = React.useMemo(() => {
    try {
      if (activeStep == 0) {
        return {
          progress: 20,
          label: "Details",
        };
      } else if (activeStep == 1) {
        return {
          progress: 48,
          label: "verification",
        };
      } else {
        return {
          progress: 100,
          label: "confirmation",
        };
      }
    } catch (error) {
      Sentry.captureException(error);
      return {
        progress: 33,
        label: "Details",
      };
    }
  }, [activeStep]);

  return (
    <div className="cashoutdiv h-full">
      <div
        className="flex flex-row items-center justify-between px-6 py-2 overflow-y-scroll"
        style={{ backgroundColor: "rgb(3, 115, 117)" }}
      >
        <span className="font-bold text-white">
          {t("Enter_Withdraw_details")}
        </span>

        <button
          id="withdraw-cancel"
          data-wthdraw-cancel="withdraw-cancel"
          onClick={() => {
            dispatch(clearSelectedBank());
            window.heap.track("Withdraw cancel", {
              email: user.email,
              country: user.country,
              phone: user.full_phone_number,
              name: user.full_name,
            });
            dispatch(openWithdraw(false));
            dispatch(
              setWithDrawData({
                toWithDraw: 0,
              })
            );
          }}
          style={{ height: 40, width: 40, borderRadius: 20 }}
          className="flex items-center justify-center shadow-lg"
        >
          <i
            id="withdraw-cancel"
            className="m-0 text-white fa fa-close"
            data-wthdraw-cancel="withdraw-cancel"
          ></i>
        </button>
      </div>
      <Box style={{ width: "100%", height: "100%" }} className="box22">
        <div className="mx-auto mt-4 mb-3 col-md-10 col-11">
          {size[0] > 745 && (
            <>
              <LinearProgress
                variant="determinate"
                value={progressBarData.progress}
              />
              <div className="flex justify-between mt-2 opacity-50">
                <div>{t("Details")}</div>
                <div>{t("verification")}</div>
                <div>{t("confirmation")}</div>
              </div>
            </>
          )}
          {size[0] < 745 && (
            <SignUpProgressBar
              customLabelClasses="md:text-md max-sm:!text-sm opacity-50"
              progress={progressBarData.progress}
              label={progressBarData.label}
              t={t}
            />
          )}
        </div>

        {activeStep === steps.length ? (
          <React.Fragment />
        ) : (
          <div className="overflow-y-scroll py-2" style={{ height: "inherit" }}>
            {renderSection()}
          </div>
        )}
      </Box>
    </div>
  );
};

export default Cashout;
