import InputField from "../forms/InputField";
import CustomDropdown from "../CustomDropdown";
import { useEffect, useMemo, useState } from "react";
import * as Sentry from "@sentry/browser";
import CustomCheckBoxListingComponent from "../CustomCheckBoxListingComponent";
import InfoMessage from "../InfoMessage";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowRight } from "@material-ui/icons";

const MobileWithdrawBox = (props: any) => {
  const {
    setwidthdrawMethod,
    widthdrawMethod,
    transfer,
    numberInputInvalidChars,
    directPaymentAmountExceed,
    allDirectPaymentMethods,
    mobileMoneyAccounts,
    userMobileMoneyAccounts,
    countryCode,
    setMethod,
    cashoutMethod,
    phone,
    setPhone,
    selectedMobileOperator,
    setselectedMobileOperator,
    customMobileOperator,
    setcustomMobileOperator,
    customPhoneNumber,
    setcustomPhoneNumber,
    fetchingAllFees,
  } = props;
  const { t } = useTranslation();

  const maxLimit = useMemo(() => {
    try {
      if (
        transfer?.getAllMobileOperator &&
        transfer?.getAllMobileOperator?.length > 0
      ) {
        const mobileOperatorObject = transfer?.getAllMobileOperator.find(
          (cashoutMethod: any) => {
            return (
              cashoutMethod &&
              cashoutMethod.cashout_method &&
              cashoutMethod.cashout_method.payment_type &&
              cashoutMethod.cashout_method.payment_type.name == "mobile_money"
            );
          }
        );
        if (mobileOperatorObject) {
          return mobileOperatorObject.cashout_method.max_amount + "€";
        }
      } else {
        return 0;
      }
    } catch (error) {
      Sentry.captureException(error);
      return 0;
    }
  }, [transfer]);

  // working

  const withdrawalFees = useMemo(() => {
    try {
      if (allDirectPaymentMethods && allDirectPaymentMethods.length > 0) {
        const feeObj = allDirectPaymentMethods.find((cashoutMethod: any) => {
          return (
            cashoutMethod.cashout_method.payment_type.name == "mobile_money"
          );
        });
        if (feeObj && feeObj.withdrawFees) {
          return feeObj.withdrawFees;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    } catch (error) {
      Sentry.captureException(error);
      return 0;
    }
  }, [allDirectPaymentMethods]);

  const methodID = useMemo(() => {
    try {
      if (cashoutMethod && cashoutMethod.length > 0) {
        const cashoutMethodObj = cashoutMethod.find((method: any) => {
          return method?.cashout_method?.payment_type?.name == "mobile_money";
        });
        if (cashoutMethodObj) {
          return cashoutMethodObj?.cashout_method?.id;
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (error) {
      Sentry.captureException(error);
      return null;
    }
  }, [cashoutMethod]);

  // users mobile list
  const [userMMAccountList, setuserMMAccountList] = useState([]);

  useEffect(() => {
    if (userMobileMoneyAccounts && userMobileMoneyAccounts.length > 0) {
      setuserMMAccountList(
        userMobileMoneyAccounts.map((mmAccount: any) => {
          return {
            ...mmAccount,
            name: `+${countryCode} ${mmAccount.phone_number}`,
            value: mmAccount.phone_number,
            active: phone == mmAccount.phone_number ? true : false,
          };
        })
      );
    }
  }, [userMobileMoneyAccounts, phone]);

  const handleMobileNumberCheck = (e: any, channelId: any) => {
    try {
      setcustomPhoneNumber("");
      setuserMMAccountList((prev: any) =>
        prev.map((channelItem: any) => {
          if (channelItem.id == channelId) {
            setPhone(channelItem.value);
            return { ...channelItem, active: true };
          } else {
            return { ...channelItem, active: false };
          }
        })
      );
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const clearCheckedMobileNumberList = () => {
    setuserMMAccountList((prev: any) =>
      prev.map((channelItem: any) => {
        return { ...channelItem, active: false };
      })
    );
  };

  return (
    <div
      className={`shadow-c rounded-lg cursor-pointer${
        widthdrawMethod == "Mobile Money" ? " border-1 border-[#037375]" : ""
      }`}
      onClick={(e) => {
        setwidthdrawMethod("Mobile Money");
        setMethod(methodID);
      }}
    >
      <div className="!items-stretch flex m-0 flex-shrink-0 min-h-[56px] px-3 !border-none max-sm:flex-wrap max-sm:gap-4 max-sm:p-2">
        <div className="flex-center mr-4">
          <div
            className={`text-[rgba(0,0,0,0.75)] text-lg font-bold flex-center pr-4 opacity-[${
              directPaymentAmountExceed && widthdrawMethod == "Mobile Money"
                ? 0.5
                : 1
            }]`}
          >
            {t("mobile")}
          </div>
        </div>

        <div className={"ml-auto my-auto hidden max-sm:block"}>
          {widthdrawMethod == "Mobile Money" ? (
            <KeyboardArrowDown
              style={{
                width: "30px",
                height: "38px",
                color: "rgba(0,0,0,0.5)",
              }}
            />
          ) : (
            <KeyboardArrowRight
              style={{
                width: "30px",
                height: "38px",
                color: "rgba(0,0,0,0.5)",
              }}
            />
          )}
        </div>

        <div className="min-w-[200px] ml-auto max-sm:m-0 flex gap-12 max-sm:gap-3 text-center max-sm:justify-between max-sm:w-full items-center">
          <div>
            <div className="text-xs  text-[rgba(0,0,0,0.5)]">{t("costs")}</div>
            {fetchingAllFees ? (
              <CircularProgress size={12} />
            ) : (
              <div className="font-bold text-[rgba(0,0,0,0.75)]">
                {withdrawalFees && withdrawalFees.toFixed(2)}€
              </div>
            )}
          </div>
          <div>
            <div className="text-xs  text-[rgba(0,0,0,0.5)]">
              {t("dealine")}
            </div>
            <div className="font-bold text-[rgba(0,0,0,0.75)]">5mn</div>
          </div>
          <div>
            <div className="text-xs  text-[rgba(0,0,0,0.5)]">
              {t("ceiling")}
            </div>
            <div className="font-bold text-[rgba(0,0,0,0.75)]">{maxLimit}</div>
          </div>
        </div>
        <div className={"ml-8 my-auto max-sm:hidden"}>
          {widthdrawMethod == "Mobile Money" ? (
            <KeyboardArrowDown
              style={{
                width: "30px",
                height: "38px",
                color: "rgba(0,0,0,0.5)",
              }}
            />
          ) : (
            <KeyboardArrowRight
              style={{
                width: "30px",
                height: "38px",
                color: "rgba(0,0,0,0.5)",
              }}
            />
          )}
        </div>
      </div>

      {widthdrawMethod == "Mobile Money" && mobileMoneyAccounts.length > 0 && (
        <div className="flex flex-col p-3 max-sm:!pt-3">
          <div className="opacity-75 text-sm mb-2">{t("mm_desc")}</div>

          <CustomDropdown
            CustomIcon={
              <KeyboardArrowDown
                style={{ width: "30px", height: "38px", color: "black" }}
              />
            }
            isDisabled={
              directPaymentAmountExceed && widthdrawMethod == "Mobile Money"
            }
            setCustomProvider={setcustomMobileOperator}
            defaultValue={
              selectedMobileOperator ||
              transfer?.transactionToRepeat?.data?.operator
            }
            setinstitutionId={() => {}}
            onchange={(val: any) => {
              setselectedMobileOperator(val);
            }}
            list={mobileMoneyAccounts}
            placeholderComponent={
              <div className="text-[rgba(0,0,0,0.5)] font-normal">
                {t("choose_operator")}
              </div>
            }
            style={{
              fontSize: 14,
              border: "1px solid rgba(0,0,0,0.2) !important",
            }}
            customClasses="px-3 bg-white rounded-xl border border-1-[rgba(0,0,0,0.2)] h-[50px]"
            placeholder={`${t("Select_Mobile_Operator")}${t("required_red")}`}
          />

          {selectedMobileOperator == "Other" && (
            <InputField
              containerClasses={"w-full mt-3"}
              extraClasses="text-[rgba(0,0,0,0.5)] !h-[50px] !m-0 text-base !border-[1px] border-[rgba(0,0,0,0.2)] mt-2 !p-4 !rounded-xl"
              disabled={
                directPaymentAmountExceed && widthdrawMethod == "Mobile Money"
              }
              handleChange={(e: any) => setcustomMobileOperator(e.target.value)}
              value={customMobileOperator}
              label=""
              type="text"
              name="customMobileOperator"
              placeholder="Mobile Operator"
            />
          )}

          {/* phone number dropdown */}
          {userMMAccountList.length > 0 && (
            <CustomCheckBoxListingComponent
              list={userMMAccountList}
              customCheckBoxClass="my-auto withdrawcheckbox"
              customMainClasses="py-3 pl-4 flex flex-col gap-2 relative"
              customListItemClasses={"font-bold"}
              isDisabled={
                directPaymentAmountExceed && widthdrawMethod == "Mobile Money"
              }
              onChange={(e: any, channelId: any) => {
                if (
                  directPaymentAmountExceed &&
                  widthdrawMethod == "Mobile Money"
                ) {
                  return;
                } else {
                  handleMobileNumberCheck(e, channelId);
                }
              }}
            />
          )}

          <div
            className="relative flex h-[50px] rounded-xl text-[rgba(0,0,0,0.5)]"
            style={{ border: "1px solid rgba(0,0,0,0.20)" }}
          >
            <div
              className={`w-[79px] text-[rgba(0,0,0,0.5)] rounded-l-xl justify-center overflow-hidden inset-y-0 flex items-center`}
              style={{
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                backgroundColor: "#F0F0F0",
              }}
            >
              +{countryCode}
            </div>
            {/* custom number */}
            <input
              disabled={
                directPaymentAmountExceed && widthdrawMethod == "Mobile Money"
              }
              onChange={(text) => {
                setcustomPhoneNumber(text.target.value);
                setPhone("");
                clearCheckedMobileNumberList();
              }}
              value={customPhoneNumber}
              className="w-full pl-2 rounded-r-xl"
              placeholder={t("choose_new_number")}
              type="number"
              onKeyDown={(e) => {
                if (numberInputInvalidChars.includes(e.key)) {
                  e.preventDefault();
                }
              }}
            />
          </div>
        </div>
      )}
      {directPaymentAmountExceed && widthdrawMethod == "Mobile Money" && (
        <InfoMessage
          customTextContainerClasses="text-sm text-[#963B3B]"
          message={`withdraw_limit_exceed`}
          innerContainerClasses="flex items-center min-h-[46px] max-sm:p-2"
          mainContainerClasses="mx-3 mb-3 flex items-center rounded bg-[#F7C3C3]"
          iconFill="#963B3B"
        />
      )}
    </div>
  );
};

export default MobileWithdrawBox;
