import React, { useEffect, useMemo, useState } from "react";
import CustomDropdown from "../CustomDropdown";
import InputField from "../forms/InputField";
import * as Sentry from "@sentry/browser";
import CustomCheckBoxListingComponent from "../CustomCheckBoxListingComponent";
import CustomInputWithLabel from "../CustomInputWithLabel";
import InfoMessage from "../InfoMessage";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../store/hooks";
import { CircularProgress } from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowRight } from "@material-ui/icons";

const BankWithdrawBox = (props: any) => {
  const {
    setwidthdrawMethod,
    widthdrawMethod,
    directPaymentAmountExceed,
    allDirectPaymentMethods,
    bankAccounts,
    userBankAccounts,
    setMethod,
    cashoutMethod,
    selectedBankName,
    setselectedBankName,
    customBankName,
    setcustomBankName,
    iBAN,
    setIBAN,
    customIban,
    setcustomIban,
    fetchingAllFees,
  } = props;
  const { t } = useTranslation();
  const {
    auth: { user },
    transfer,
  } = useAppSelector((state) => state.persistedReducer);

  // working
  const methodID = useMemo(() => {
    try {
      if (cashoutMethod && cashoutMethod.length > 0) {
        const cashoutMethodObj = cashoutMethod.find((method: any) => {
          return (
            method?.cashout_method?.payment_type?.name ==
              "manual_bank_transfer" ||
            method?.cashout_method?.payment_type?.name == "bank_transfer"
          );
        });
        if (cashoutMethodObj) {
          return cashoutMethodObj?.cashout_method?.id;
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (error) {
      Sentry.captureException(error);
      return null;
    }
  }, [cashoutMethod]);

  const withdrawalFees = useMemo(() => {
    try {
      if (allDirectPaymentMethods && allDirectPaymentMethods.length > 0) {
        const feeObj = allDirectPaymentMethods.find((cashoutMethod: any) => {
          return (
            cashoutMethod.cashout_method.payment_type.name ==
              "manual_bank_transfer" ||
            cashoutMethod.cashout_method.payment_type.name == "bank_transfer"
          );
        });
        if (feeObj && feeObj.withdrawFees) {
          return feeObj.withdrawFees;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    } catch (error) {
      Sentry.captureException(error);
      return 0;
    }
  }, [allDirectPaymentMethods]);

  const maxLimit = useMemo(() => {
    try {
      if (
        transfer?.getAllMobileOperator &&
        transfer?.getAllMobileOperator?.length > 0
      ) {
        const mobileOperatorObject = transfer?.getAllMobileOperator.find(
          (cashoutMethod: any) => {
            return (
              cashoutMethod &&
              cashoutMethod.cashout_method &&
              cashoutMethod.cashout_method.payment_type &&
              (cashoutMethod.cashout_method.payment_type.name ==
                "manual_bank_transfer" ||
                cashoutMethod.cashout_method.payment_type.name ==
                  "bank_transfer")
            );
          }
        );
        if (mobileOperatorObject) {
          return mobileOperatorObject.cashout_method.max_amount + "€";
        }
      } else {
        return 0;
      }
    } catch (error) {
      Sentry.captureException(error);
      return 0;
    }
  }, [transfer]);

  const [beneficiaryIbanList, setBeneficiaryIbanList] = useState([]);
  useEffect(() => {
    try {
      if (userBankAccounts) {
        setBeneficiaryIbanList(
          userBankAccounts.map((item: any) => {
            if (item.id == transfer?.withdraw?.data?.bank_account_id) {
              setIBAN(item.iban);
              return {
                ...item,
                name: item.iban,
                active: true,
              };
            } else {
              return {
                ...item,
                name: item.iban,
                active: false,
              };
            }
          })
        );
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }, [userBankAccounts, transfer?.withdraw?.data?.bank_account_id]);

  const handleIbanCheck = (e: any, channelId: any) => {
    try {
      setcustomIban("");
      setBeneficiaryIbanList((prev: any) =>
        prev.map((channelItem: any) => {
          if (channelItem.id == channelId) {
            setIBAN(channelItem.name);
            return { ...channelItem, active: true };
          } else {
            return { ...channelItem, active: false };
          }
        })
      );
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const clearIbanList = () => {
    setBeneficiaryIbanList((prev: any) =>
      prev.map((channelItem: any) => {
        return { ...channelItem, active: false };
      })
    );
  };

  return (
    <div
      className={`shadow-c rounded-lg cursor-pointer${
        widthdrawMethod == "Bank Account" ? " border-1 border-[#037375]" : ""
      }`}
      onClick={(e) => {
        setwidthdrawMethod("Bank Account");
        setMethod(methodID);
      }}
    >
      <div className="!items-stretch flex m-0 flex-shrink-0 min-h-[56px] px-3 !border-none max-sm:flex-wrap max-sm:gap-4 max-sm:p-2">
        <div className="flex-center mr-4">
          <div
            className={`text-[rgba(0,0,0,0.75)] text-lg font-bold flex-center pr-4 opacity-[${
              directPaymentAmountExceed && widthdrawMethod == "Bank Account"
                ? 0.5
                : 1
            }]`}
          >
            {t("bank")}
          </div>
        </div>

        <div className={"ml-auto my-auto hidden max-sm:block"}>
          {widthdrawMethod == "Bank Account" ? (
            <KeyboardArrowDown
              style={{
                width: "30px",
                height: "38px",
                color: "rgba(0,0,0,0.5)",
              }}
            />
          ) : (
            <KeyboardArrowRight
              style={{
                width: "30px",
                height: "38px",
                color: "rgba(0,0,0,0.5)",
              }}
            />
          )}
        </div>

        <div className="min-w-[200px] ml-auto max-sm:m-0 flex gap-12 max-sm:gap-3 text-center max-sm:justify-between max-sm:w-full items-center">
          <div>
            <div className="text-xs  text-[rgba(0,0,0,0.5)]">{t("costs")}</div>
            {fetchingAllFees ? (
              <CircularProgress size={12} />
            ) : (
              <div className="font-bold text-[rgba(0,0,0,0.75)]">
                {withdrawalFees && withdrawalFees.toFixed(2)}€
              </div>
            )}
          </div>
          <div>
            <div className="text-xs  text-[rgba(0,0,0,0.5)]">
              {t("dealine")}
            </div>
            <div className="font-bold text-[rgba(0,0,0,0.75)]">72h</div>
          </div>
          <div>
            <div className="text-xs  text-[rgba(0,0,0,0.5)]">
              {t("ceiling")}
            </div>
            <div className="font-bold text-[rgba(0,0,0,0.75)]"> {maxLimit}</div>
          </div>
        </div>
        <div className={"ml-8 my-auto max-sm:hidden"}>
          {widthdrawMethod == "Bank Account" ? (
            <KeyboardArrowDown
              style={{
                width: "30px",
                height: "38px",
                color: "rgba(0,0,0,0.5)",
              }}
            />
          ) : (
            <KeyboardArrowRight
              style={{
                width: "30px",
                height: "38px",
                color: "rgba(0,0,0,0.5)",
              }}
            />
          )}
        </div>
      </div>

      {widthdrawMethod == "Bank Account" && bankAccounts.length > 0 && (
        <div className="flex flex-col p-3 max-sm:!py-3">
          <div className="opacity-75 text-sm mb-2">
            {t("PleaseSpecifyYourRecipient")}
          </div>

          <CustomInputWithLabel
            mainText={user?.full_name}
            sideLabelText={"EUR"}
            label="account_holder"
            mainTextClasses={
              directPaymentAmountExceed && widthdrawMethod == "Bank Account"
                ? "font-bold text-md uppercase bg-transparent opacity-25"
                : "font-bold text-md uppercase bg-transparent opacity-50"
            }
            mainContainerClasses="rounded-xl border border-1-[rgba(0,0,0,0.2)] mb-3 flex px-3 justify-center flex-col bg-white h-[50px]"
          />

          {bankAccounts.length > 0 && (
            <CustomDropdown
              CustomIcon={
                <KeyboardArrowDown
                  style={{ width: "30px", height: "38px", color: "black" }}
                />
              }
              isDisabled={
                directPaymentAmountExceed && widthdrawMethod == "Bank Account"
              }
              setCustomProvider={setcustomBankName}
              defaultValue={
                selectedBankName || transfer?.withdraw?.data?.bank_name
              }
              setinstitutionId={() => {}}
              onchange={(val: any) => {
                setselectedBankName(val);
              }}
              list={bankAccounts}
              placeholderComponent={
                <div className="opacity-50 font-normal">{t("choose_bank")}</div>
              }
              customClasses="px-3 bg-white rounded-xl  border border-1-[rgba(0,0,0,0.2)] h-[50px]"
              placeholder={t("select_the_bank")}
            />
          )}
          {selectedBankName == "Other" && (
            <InputField
              containerClasses={"w-full mt-3"}
              extraClasses="text-[rgba(0,0,0,0.5)] !h-[50px] !m-0 text-base !border-[1px] border-[rgba(0,0,0,0.2)] mt-2 !p-4 !rounded-xl"
              disabled={
                directPaymentAmountExceed && widthdrawMethod == "Bank Account"
              }
              handleChange={(e: any) => setcustomBankName(e.target.value)}
              value={customBankName}
              label=""
              type="text"
              name="customBankName"
              placeholder={t("Bank_Name")}
            />
          )}

          {beneficiaryIbanList.length > 0 && (
            <CustomCheckBoxListingComponent
              customCheckBoxClass="my-auto withdrawcheckbox"
              isDisabled={
                directPaymentAmountExceed && widthdrawMethod == "Bank Account"
              }
              list={beneficiaryIbanList}
              customListItemClasses={"font-bold"}
              customMainClasses="py-3 pl-4 flex flex-col gap-2 relative"
              onChange={(e: any, channelId: any) => {
                if (
                  directPaymentAmountExceed &&
                  widthdrawMethod == "Bank Account"
                ) {
                  return;
                } else {
                  handleIbanCheck(e, channelId);
                }
              }}
            />
          )}

          {/* other iban */}
          <InputField
            containerClasses={
              "w-full border-1-[rgba(0,0,0,0.2)] border rounded-xl"
            }
            disabled={
              directPaymentAmountExceed && widthdrawMethod == "Bank Account"
            }
            handleChange={(e: any) => {
              setcustomIban(e.target.value);
              setIBAN("");
              clearIbanList();
            }}
            value={customIban}
            extraClasses="text-[rgba(0,0,0,0.5)] !h-[50px] !m-0 text-base !border-none mt-2 !p-4 !rounded-xl"
            label=""
            type="text"
            name="IBAN"
            placeholder={t("choose_iban")}
          />
        </div>
      )}
      {directPaymentAmountExceed && widthdrawMethod == "Bank Account" && (
        <InfoMessage
          customTextContainerClasses="text-sm text-[#963B3B]"
          message={`withdraw_limit_exceed`}
          innerContainerClasses="flex items-center min-h-[46px] max-sm:p-2"
          mainContainerClasses="mx-3 flex items-center mb-3 rounded bg-[#F7C3C3]"
          iconFill="#963B3B"
        />
      )}
    </div>
  );
};

export default BankWithdrawBox;
