import { useEffect, useMemo, useState } from "react";
import {
  addUser,
  saveRegistrationProgress,
} from "../../store/features/Auth/Auth";
import * as Sentry from "@sentry/browser";
import CustomModal from "../CustomModal";
import { useDispatch } from "react-redux";
import { setSignUpData } from "../../store/features/Auth/AuthSlice";
import { isEmpty } from "../../utilities/help";
import { toast } from "material-react-toastify";
import SignUpDiscoveryModal from "../DiscoveryModal/SignUpDiscoveryModal";
import UsertypeSelection from "./UsertypeSelection";
import { log } from "console";
import ScrollableModal from "../ScrollableModal";

const DiscoveryAndUsertypeModal = (props: any) => {
  const {
    discoverySubStep,
    t,
    discoveryMethod,
    setdiscoverySubStep,
    setProcessing,
    typeOfUser,
    activeStep,
    handleNext,
    handleUserType,
    setprogressBarData,
    signUpData,
    processing,
    user,
  } = props;
  const dispatch = useDispatch();

  const [confirmationModalOpen, setconfirmationModalOpen] = useState(false);
  const usertype = useMemo(() => {
    const usertype = typeOfUser.find((itm: any) => itm.active);
    return usertype.value;
  }, [typeOfUser]);

  const Content = () => {
    return (
      <div className="text-center">
        <>{t("usertype_info_modal_newbody_1")}</>
        <b>{t("usertype_info_modal_newbody_2")}</b>
        <b className="mt-4 block">{t("usertype_info_modal_body_5")}</b>
      </div>
    );
  };
  const [isSoleProprietorship, setIsSoleProprietorship] = useState(
    signUpData?.is_sole_proprietorship || false
  );
  // set proprietorship from saved data
  useEffect(() => {
    if (!isEmpty(signUpData?.is_sole_proprietorship)) {
      setIsSoleProprietorship(signUpData?.is_sole_proprietorship);
    } else if (!isEmpty(user?.progression?.is_sole_proprietorship)) {
      setIsSoleProprietorship(user?.progression?.is_sole_proprietorship);
    }
  }, [signUpData, user]);

  const onNext = () => {
    try {
      const usertype: any = typeOfUser.find((itm: any) => itm.active);
      setProcessing(true);
      dispatch(
        setSignUpData({
          ...user?.progression,
          ...signUpData,
          usertype: usertype.value,
          is_sole_proprietorship: isSoleProprietorship,
          step: 0,
        })
      );
      saveRegistrationProgress({
        ...user?.progression,
        ...signUpData,
        usertype: usertype.value,
        is_sole_proprietorship: isSoleProprietorship,
        step: 0,
      }).finally(() => {
        let verificationPayload = {
          ...user?.progression,
          ...signUpData,
          usertype: usertype.value,
          is_sole_proprietorship: isSoleProprietorship,
          step: 0,
          reason_for_modification: "Add user type details",
        };

        delete verificationPayload.address_line;
        delete verificationPayload.city;
        delete verificationPayload.countryName;
        delete verificationPayload.country_code;
        delete verificationPayload.phone_number;
        delete verificationPayload.post_code;

        addUser(
          {
            ...verificationPayload,
          },
          "individual"
        )
          .then(() => {
            setProcessing(false);
            handleNext();
            setprogressBarData({
              progress: 25,
              title:
                usertype.value === "individual"
                  ? "personal_information"
                  : "personal_information",
            });
          })
          .catch((err) => {
            Sentry.captureException(err);
            if (err?.data?.errors) {
              try {
                Object.keys(err?.data?.errors).forEach((error: any) => {
                  toast.error(`${t(error)}${t("is_invalid")}`);
                });
              } catch (error) {
                Sentry.captureException(error);
              }
            }
          })
          .finally(() => {
            setProcessing(false);
          });
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <ScrollableModal>
      <>
        {discoverySubStep == 1 && (
          <SignUpDiscoveryModal
            extraclass={"py-5 flex-col items-center"}
            isDashboardModal="false"
            selectedValue={discoveryMethod}
            onNext={(values: any, setSubmitting: any) => {
              setSubmitting(true);
              dispatch(
                setSignUpData({
                  ...signUpData,
                  ...values,
                  step: 0,
                })
              );
              saveRegistrationProgress({
                ...signUpData,
                ...values,
                step: 0,
              })
                .catch((error: any) => {
                  toast.warning(t(error?.data?.message));
                  Sentry.captureException(error);

                  setProcessing(false);
                  error?.data?.errors?.referral_code?.forEach(
                    (element: any) => {
                      toast.warning(t(element));
                    }
                  );
                })
                .finally(() => {
                  setdiscoverySubStep(2);
                  setSubmitting(false);
                });
            }}
          />
        )}
        {discoverySubStep == 2 && (
          <UsertypeSelection
            typeOfUser={typeOfUser}
            usertype={usertype}
            processing={processing}
            onNext={onNext}
            handleUserType={handleUserType}
            setdiscoverySubStep={setdiscoverySubStep}
            setIsSoleProprietorship={setIsSoleProprietorship}
            isSoleProprietorship={isSoleProprietorship}
            setconfirmationModalOpen={setconfirmationModalOpen}
          />
        )}

        {/* company confirmation modal */}
        <CustomModal
          showCloseButton={true}
          onClose={() => setconfirmationModalOpen(false)}
          showHorizontalRuler={true}
          headerClasses="flex justify-between items-center mb-3.5"
          open={confirmationModalOpen}
          containerClasses={"h-full flex-center"}
          modalClasses={
            "bg-white flex flex-col rounded-xl max-w-[341px] min-h-[291px] p-4"
          }
          titleClasses={"font-bold text-xl max-sm:text-xl"}
          title={t("confirmation")}
          content={t("usertype_info_modal_body")}
          onNext={onNext}
          onNextTitle={"usertype_info_modal_continue"}
          buttonContainerClasses={"flex gap-3 mt-auto"}
          contentComponent={<Content />}
          backBtnClasses={"bg-white !text-black border"}
          disabled={processing}
          nextBtnClasses={
            processing
              ? "w-full min-h-[48px] flex-1 rounded-xl text-white capitalize text-sm font-bold bg-black opacity-50"
              : "w-full min-h-[48px] flex-1 rounded-xl text-white capitalize text-sm font-bold bg-black"
          }
        />
      </>
    </ScrollableModal>
  );
};

export default DiscoveryAndUsertypeModal;
