import { Modal } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import SelectableBoxListing from "../SelectableBoxListing";
import { useEffect, useState } from "react";
import * as Sentry from "@sentry/browser";
import CustomInputField from "./CustomInputField";
import CustomButton from "../CustomButton";
import moment from "moment";
import { toast } from "material-react-toastify";
import { prependZero } from "../../utilities/help";

const list = [
  {
    id: 1,
    name: "SMS",
    image: "sms",
    active: true,
  },
  {
    id: 2,
    name: "Whatsapp",
    image: "whatsapp",
    active: false,
  },
];

const SignUpOtpVerification = (props: any) => {
  const {
    open,
    onClose = () => {},
    otp,
    setotp,
    onSubmit = () => {},
    onResend,
    loading = false,
    formhandleChange = () => {},
    isOtpSent,
  } = props;
  const { t } = useTranslation();

  // resend logic
  const [disableResend, setdisableResend] = useState(false);
  const [seconds, setseconds] = useState(30);
  const [isTimerRunning, setisTimerRunning] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 1) {
        isTimerRunning && setseconds(seconds - 1);
      }

      if (seconds === 1) {
        setdisableResend(false);
        setisTimerRunning(false);
        clearInterval(interval);
        isTimerRunning && setseconds(30);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  const handleOTPAbuse = (successCallback: any) => {
    try {
      if (window.localStorage.getItem("otpresendtime") == undefined) {
        const payload = { times: 1, time: moment() };
        window.localStorage.setItem("otpresendtime", JSON.stringify(payload));
        successCallback();
      } else {
        const oldPayload = JSON.parse(
          window.localStorage.getItem("otpresendtime")!
        );

        const isTimeLessThanTwoMin =
          moment.duration(moment().diff(moment(oldPayload.time))).minutes() < 2;

        if (parseInt(oldPayload.times) >= 5 && isTimeLessThanTwoMin) {
          toast.error(t("otp_limit_error"));
          setTimeout(() => {
            window.localStorage.removeItem("otpresendtime");
          }, 2 * 60 * 1000);
        } else {
          const payload = { ...oldPayload, times: oldPayload.times + 1 };
          window.localStorage.setItem("otpresendtime", JSON.stringify(payload));
          successCallback();
        }
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  const startTimer = () => {
    const successCallback = () => {
      if (!disableResend) {
        setisTimerRunning(true);
        setdisableResend(true);
        onResend && onResend();
        setseconds(30);
      }
    };
    handleOTPAbuse(successCallback);
  };

  // channel
  const [channelList, setChannelList] = useState(list);

  const handleChannelChange = (channelName: any) => {
    try {
      setChannelList((prev: any) =>
        prev.map((channelItem: any) => {
          if (channelItem.name.toLowerCase() == channelName.toLowerCase()) {
            formhandleChange("channel")(channelItem.name);
            return { ...channelItem, active: true };
          } else {
            return { ...channelItem, active: false };
          }
        })
      );
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handleChange = (e: any) => {
    setotp(e.target.value);
  };

  return (
    <Modal open={open} onClose={onClose} className="flex-center">
      <div className="bg-white rounded-xl max-w-[450px] w-full min-h-[391px] p-4">
        <div className="mb-3.5 flex justify-between items-center">
          <div className="text-xl font-bold">{t("OTP_Verification")}</div>
          <button
            onClick={onClose}
            style={{ height: 40, width: 40, borderRadius: 20 }}
            className="flex items-center justify-center shadow-md"
          >
            <Close />
          </button>
        </div>
        <hr className="mb-4" />

        <div className="mb-2">{t("select_otp_channel")}</div>

        <SelectableBoxListing
          list={channelList}
          onClick={handleChannelChange}
          listingClasses="flex-1 rounded-xl min-h-[56px] py-2 pr-3 pl-4 shadow-c"
          containerClasses="flex max-sm:flex-col justify-between gap-4 mb-3"
        />

        <CustomInputField
          mainContainerClasses="w-full mb-4"
          name="otp"
          type="number"
          placeholder={t("enter_code")}
          value={otp}
          handleChange={handleChange}
          disabled={!isOtpSent}
          error={
            otp.length == 6 || loading
              ? ""
              : otp.length == 0
              ? ""
              : t("otp_invalid")
          }
        />

        <CustomButton
          classNames="w-full min-h-[48px] flex-1 rounded-xl text-white capitalize text-sm font-bold bg-black"
          label="next"
          disabled={/^(\d){6}$/.test(otp) == false || loading}
          onClick={onSubmit}
        />

        <div
          className="mt-2"
          onClick={() => {
            !disableResend && startTimer();
          }}
        >
          {disableResend ? (
            <>
              {t("resend_code_in")}
              <span className="text-[#038385] font-bold">
                00:{prependZero(seconds)}{" "}
              </span>
              {t("seconds")}
            </>
          ) : (
            <div className="text-[#038385] font-bold text-center cursor-pointer">
              {t("click_to_receive_code")}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default SignUpOtpVerification;
