import { Form, Formik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { setSignUpData } from "../../store/features/Auth/AuthSlice";
import * as Sentry from "@sentry/browser";
import { Alert } from "@material-ui/lab";
import { buildString, isEmpty } from "../../utilities/help";
import {
  addUser,
  checkUserNameIfReal,
  doesPhoneExists,
  saveRegistrationProgress,
} from "../../store/features/Auth/Auth";
import GoogleAutoCompleteField from "../forms/GoogleAutoCompleteField";

import {
  get_phone_otp,
  verify_phone_otp,
} from "../../store/features/Auth/Auth";
import { toast } from "material-react-toastify";
import CustomInputField from "./CustomInputField";
import PhoneNumberPicker from "./PhoneNumberPicker";
import CustomButton from "../CustomButton";
import SignUpOtpVerification from "./SignUpOtpVerification";
import CustomModal from "../CustomModal";
import { useTranslation } from "react-i18next";
import FormErrorText from "../FormErrorText";
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  validatePhoneNumberLength,
} from "libphonenumber-js/max";

const genderList = [
  {
    id: 1,
    name: "Male",
  },
  {
    id: 2,
    name: "Female",
  },
];

const Content = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center">
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="101"
          height="94"
          fill="#FA892D"
          viewBox="0 0 256 256"
        >
          <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm-8-80V80a8,8,0,0,1,16,0v56a8,8,0,0,1-16,0Zm20,36a12,12,0,1,1-12-12A12,12,0,0,1,140,172Z"></path>
        </svg>
      </div>
      <div className="text-center text-xl w-[300px] mb-4 !mt-4">
        {t("fake_name_warning")}
      </div>
    </div>
  );
};

const PersonalInfoScreen = (props: any) => {
  const {
    t,
    user,
    signUpData,
    phone,
    country,
    setErrorsState,
    typeOfUser,
    dispatch,
    setActiveStep,
    setProcessing,
    uservalue,
    Yup,
    setprogressBarData,
    setCountry,
    setIsIndividualUser,
    setisUserComingBack,
    setCountryName,
    setPhone,
    errorsState,
    setSelectedCountryReset,
    handleBack,
    processing,
  } = props;

  // clean the div left by google autocomplete
  useEffect(() => {
    try {
      return () => {
        let elements: any = document.getElementsByClassName("pac-container");
        while (elements.length > 0) {
          elements[0].parentNode.removeChild(elements[0]);
        }
      };
    } catch (error) {
      Sentry.captureException(error);
    }
  }, []);

  // contact/personal information schema
  const validationSchema = useMemo(() => {
    if (uservalue == "individual") {
      return Yup.object().shape({
        lastname: Yup.string().required("LNR"),
        // gender: Yup.string().required("gender_required"),
        firstname: Yup.string().required("FNR"),
        phone_number: Yup.string()
          .required("PhoneNumberError")
          .test(
            "yourTestCondition",
            "invalid_phone_number",
            function (value: any, context: any) {
              const receipentNumber =
                "+" + context.parent.country_code + value?.toString();

              if (
                !isValidPhoneNumber(receipentNumber) ||
                !isPossiblePhoneNumber(receipentNumber)
              ) {
                if (
                  validatePhoneNumberLength(receipentNumber) == undefined ||
                  validatePhoneNumberLength(receipentNumber) ==
                    "INVALID_COUNTRY"
                ) {
                  return false;
                } else {
                  return true;
                }
              } else {
                return true;
              }
            }
          )
          .test(
            "yourTestCondition",
            "phone_number_below_min_length",
            function (value: any, context: any) {
              const receipentNumber =
                "+" + context.parent.country_code + value?.toString();
              if (validatePhoneNumberLength(receipentNumber) == "TOO_SHORT") {
                return false;
              } else {
                return true;
              }
            }
          )
          .test(
            "yourTestCondition",
            "phone_number_below_max_length",
            function (value: any, context: any) {
              const receipentNumber =
                "+" + context.parent.country_code + value?.toString();

              if (validatePhoneNumberLength(receipentNumber) == "TOO_LONG") {
                return false;
              } else {
                return true;
              }
            }
          ),
        country_code: Yup.string().required("CE"),
        city: Yup.string().required("CIE"),
        address_information: Yup.string().required("AIE"),
        post_code: Yup.string().test(
          "yourTestCondition",
          "post_code_error",
          function (value: any, context: any) {
            return (
              (this.parent.isPostCodeRequired == "true" && !isEmpty(value)) ||
              this.parent.isPostCodeRequired == "false"
            );
          }
        ),
        isPostCodeRequired: Yup.string(),
      });
    } else {
      return Yup.object().shape({
        lastname: Yup.string().required("LNR"),
        firstname: Yup.string().required("FNR"),
        phone_number: Yup.string()
          .required("PhoneNumberError")
          .test(
            "yourTestCondition",
            "invalid_phone_number",
            function (value: any, context: any) {
              const receipentNumber =
                "+" + context.parent.country_code + value?.toString();

              if (
                !isValidPhoneNumber(receipentNumber) ||
                !isPossiblePhoneNumber(receipentNumber)
              ) {
                if (
                  validatePhoneNumberLength(receipentNumber) == undefined ||
                  validatePhoneNumberLength(receipentNumber) ==
                    "INVALID_COUNTRY"
                ) {
                  return false;
                } else {
                  return true;
                }
              } else {
                return true;
              }
            }
          )
          .test(
            "yourTestCondition",
            "phone_number_below_min_length",
            function (value: any, context: any) {
              const receipentNumber =
                "+" + context.parent.country_code + value?.toString();
              if (validatePhoneNumberLength(receipentNumber) == "TOO_SHORT") {
                return false;
              } else {
                return true;
              }
            }
          )
          .test(
            "yourTestCondition",
            "phone_number_below_max_length",
            function (value: any, context: any) {
              const receipentNumber =
                "+" + context.parent.country_code + value?.toString();

              if (validatePhoneNumberLength(receipentNumber) == "TOO_LONG") {
                return false;
              } else {
                return true;
              }
            }
          ),
        country_code: Yup.string().required("CE"),
        post_code: Yup.string(),
        isPostCodeRequired: Yup.string(),
      });
    }
  }, [uservalue]);

  const handleHeapAndProgressBar = (usertype: any, json: any) => {
    // heap events
    setprogressBarData({
      progress: usertype.value === "individual" ? 40 : 50,
      title:
        usertype.value === "individual"
          ? "revenue_information"
          : "Company_Information",
    });
    if (usertype.value === "individual") {
      window.heap.track("individual identity information set", {
        ...json,
      });
      window.heap.track("Has logged in as an individual", {
        email: user?.email,
      });
    } else {
      window.heap.track("company identity information set", {
        ...json,
      });
      window.heap.track("Has logged in as a company", {
        email: user?.email,
      });
    }
  };

  // OTP
  const [otp, setotp] = useState("");
  const [isOtpModalOpen, setisOtpModalOpen] = useState(false);
  const [isOtpSent, setisOtpSent] = useState(false);

  const sendOTP = (values: any, isResend = false) => {
    try {
      !isResend && setProcessing(true);
      const { country_code, phone_number } = values;
      get_phone_otp({
        country_code,
        phone_number,
        channel: values.channel ? values.channel.toLowerCase() : "sms",
      })
        .then(() => {
          setisOtpSent(true);
        })
        .catch((error: any) => {
          Sentry.captureException(error);
          toast.error(
            t(error?.data?.error) ||
              t(error?.data?.message) ||
              t("otp_failed_default_error")
          );
        })
        .finally(() => setProcessing(false));
    } catch (error) {
      setProcessing(false);
      Sentry.captureException(error);
    }
  };

  const validateOTP = (handleSubmit: any, values: any) => {
    try {
      setProcessing(true);
      const { country_code, phone_number } = values;
      verify_phone_otp({
        phone_number: phone_number,
        country_code: country_code,
        verification_code: otp,
      })
        .then(() => handleSubmit())
        .catch((error: any) => {
          setProcessing(false);
          if (error?.data?.error == "Invalid Verification code") {
            toast.error(t("invalid_otp"));
          } else {
            toast.error(t(error?.data?.error) || t("otp_failed_default_error"));
          }
        })
        .finally(() => {
          setotp("");
        });
    } catch (error) {
      toast.error(t(error));
      setProcessing(false);
      Sentry.captureException(error);
    }
  };

  const [fakeUserNameWarningModalOpen, setfakeUserNameWarningModalOpen] =
    useState(false);
  const validateUserName = (values: any, onSubmit: any, setSubmitting: any) => {
    try {
      const full_name = values?.firstname + " " + values?.lastname;
      checkUserNameIfReal({ full_name }).then((response: any) => {
        if (response.isRealName) {
          setfakeUserNameWarningModalOpen(false);
          setisOtpModalOpen(true);
          // onSubmit(values, { setSubmitting });
        } else {
          setfakeUserNameWarningModalOpen(true);
        }
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const onSubmit = (data: any, { setSubmitting }: any) => {
    try {
      setSubmitting(true);
      setProcessing(true);
      setErrorsState([]);
      const json = {
        email: user?.email,
        phone_number: data.phone_number || phone,
        country_code: parseInt(data.country_code) || parseInt(country),
        first_name: data.firstname,
        last_name: data.lastname,
        address_line: data.address_information,
        city: data?.city,
        post_code: data?.post_code,
        countryName: data?.countryName,
      };
      const usertype: any = typeOfUser.find((itm: any) => itm.active);

      setIsIndividualUser(usertype.value === "individual");

      setisUserComingBack(false);

      // add company to user profile if it exists
      if (uservalue !== "individual") {
        let payload: any = { ...json };
        delete payload.address_line;
        delete payload.city;
        delete payload.post_code;

        if (user?.company !== undefined && user?.company !== null) {
          payload.company = user?.company;
        }
        dispatch(setSignUpData({ ...signUpData, ...payload }));
        saveRegistrationProgress({
          ...signUpData,
          ...payload,
          usertype: "business",
          step: 1,
        })
          .then(() => window.localStorage.removeItem("otpresendtime"))
          .finally(() => {
            setActiveStep(2);
            setProcessing(false);
            handleHeapAndProgressBar(usertype, json);
            setSubmitting(false);
          });
      } else {
        let payload: any = { ...json };
        delete payload.is_sole_proprietorship;
        dispatch(
          setSignUpData({
            ...signUpData,
            ...payload,

            usertype: "individual",
          })
        );
        saveRegistrationProgress({
          ...signUpData,
          ...payload,
          usertype: "individual",
          step: 1,
        })
          .then(() => window.localStorage.removeItem("otpresendtime"))
          .finally(() => {
            let verificationPayload = {
              ...signUpData,
              ...payload,
            };
            addUser(
              {
                event: verificationPayload?.event,
                referral_code: verificationPayload?.referral_code,
                user_origin: verificationPayload.user_origin,
                is_sole_proprietorship:
                  verificationPayload.is_sole_proprietorship,
                ...payload,
                usertype: "individual",
                step: 1,
                reason_for_modification: "Add user type details",
              },
              "individual"
            )
              .then(() => {
                setProcessing(false);
                setActiveStep(2);
                handleHeapAndProgressBar(usertype, json);
                setSubmitting(false);
              })
              .catch((err) => {
                toast.error(t(err?.data?.message));
                Sentry.captureException(err);
                if (err?.data?.errors) {
                  try {
                    Object.keys(err?.data?.errors).forEach((error: any) => {
                      toast.error(`${t(error)}${t("is_invalid")}`);
                    });
                  } catch (error) {
                    Sentry.captureException(error);
                  }
                }
              })
              .finally(() => {
                setisOtpModalOpen(false);
                setProcessing(false);
                setSubmitting(false);
                setisOtpSent(false);
              });
          });
      }
    } catch (error: any) {
      setSubmitting(false);
      Sentry.captureException(error);
      setProcessing(false);
    }
  };

  const handlePhoneNumberForExistence = (
    country_code: any,
    phone_number: any,
    validateUserName: any,
    values: any,
    onSubmit: any,
    setSubmitting: any
  ) => {
    doesPhoneExists(country_code, phone_number)
      .then((response: any) => {
        if (response?.exists) {
          toast.error(t("phone_already_exists"));
        } else {
          validateUserName(values, onSubmit, setSubmitting);
        }
      })
      .catch((error: any) => {
        Sentry.captureException(error);
        toast.error(t(error?.data?.message));
      });
  };
  return (
    <div className="flex flex-col justify-center px-4 py-7 mb-5 max-sm:px-4 max-sm:py-8 w-full bg-white rounded-xl shadow-c">
      <div className="font-bold text-center text-2xl">
        {t("Personal_Information")}
      </div>
      <div className="opacity-75 text-center mb-7">{t("enter_info")}</div>
      <Formik
        validateOnMount
        validateOnChange
        enableReinitialize
        key="personalinfo"
        initialValues={{
          // gender:
          //   user?.gender ||
          //   signUpData?.gender ||
          //   user?.progression?.gender ||
          //   "",
          lastname:
            signUpData?.last_name ||
            user?.last_name ||
            user?.progression?.last_name ||
            "",
          firstname:
            signUpData?.first_name ||
            user?.first_name ||
            user?.progression?.first_name ||
            "",
          phone_number:
            signUpData?.phone_number ||
            user?.phone_number ||
            user?.progression?.phone_number ||
            "",
          country_code:
            signUpData?.country_code ||
            user?.country_code ||
            user?.progression?.country_code ||
            "",
          address_information:
            signUpData?.address_line ||
            user?.address_line ||
            user?.progression?.address_line ||
            "",
          city: signUpData?.city || user?.city || user?.progression?.city || "",
          post_code: signUpData?.post_code || "",
          isPostCodeRequired: "false",
          country: "",
          countryName:
            signUpData?.countryName || user?.progression?.countryName || "",
        }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({
          values,
          handleChange,
          handleBlur,
          errors,
          touched,
          isSubmitting,
          isValid,
          setFieldValue,
          setValues,
          handleSubmit,
          setSubmitting,
        }) => (
          <Form className="individual-registration">
            {/* {uservalue == "individual" && (
              <CustomDropdown
                CustomIcon={
                  <KeyboardArrowDown
                    style={{ width: "18px", height: "21px", color: "black" }}
                  />
                }
                placeholderClasses="opacity-50 font-normal"
                placeholder={t("select_gender_placeholder")}
                customClasses="!rounded-xl h-[50px] w-full border-1 mb-2 pl-5 pr-2 border-[rgba(0,0,0,0.2)]"
                list={genderList}
                onchange={(val: any) => {
                  handleChange("gender")(val);
                  try {
                  } catch (error) {
                    Sentry.captureException(error);
                  }
                }}
              />
            )} */}

            <CustomInputField
              mainContainerClasses="w-full mb-2"
              name="lastname"
              type="text"
              placeholder={t("LastName")}
              handleChange={handleChange}
              value={values.lastname}
              error={isSubmitting ? "" : t(`${errors.lastname}`)}
              touched={touched.lastname}
              onBlur={handleBlur}
            />

            <CustomInputField
              mainContainerClasses="w-full mb-2"
              name="firstname"
              type="text"
              placeholder={t("FirstName")}
              value={values.firstname}
              handleChange={handleChange}
              error={isSubmitting ? "" : t(`${errors.firstname}`)}
              touched={touched.firstname}
              onBlur={handleBlur}
            />

            <div className="mb-2">
              <PhoneNumberPicker
                type="number"
                phoneNumberClasses="ml-2 w-full"
                placeholder={t("enter_phone_number")}
                country={values?.countryName}
                value={values?.phone_number}
                handleChange={(e: any) => {
                  setPhone(e.target.value);
                  handleChange("phone_number")(e.target.value);
                }}
                mainContainerClasses="w-full relative"
                setCountry={(text: any) => {
                  setCountry(text.country_code);
                  setCountryName(text.name);
                  setValues({
                    ...values,
                    country_code: text.country_code,
                    country: text.code && text.code.toLowerCase(),
                    countryName: text.name,
                    isPostCodeRequired: (
                      text.requires_post_code == 1
                    ).toString(),
                  });
                }}
              />
              {errors.phone_number && (
                <FormErrorText
                  errorMessage={errors.phone_number}
                  classNames="text-xs"
                />
              )}
            </div>

            {uservalue == "individual" && (
              <GoogleAutoCompleteField
                containerClasses="w-full"
                value={values.address_information}
                country={values.country}
                className="w-full pl-5 pr-5 border-1 border-[rgba(0,0,0,0.2)] rounded-xl min-h-[50px] py-3"
                placeholder={t("AddressInformation")}
                getPostalCode={(postalCode: any) => {
                  setFieldValue("post_code", postalCode || "");
                }}
                getLocality={(locality: any) => {
                  setFieldValue("city", locality || "");
                }}
                onChange={(value: any) => {
                  setFieldValue(
                    "address_information",
                    value?.formatted_address || value
                  );
                }}
              />
            )}

            {/* country city selection */}
            {uservalue == "individual" && (
              <>
                <CustomInputField
                  mainContainerClasses="w-full mb-2"
                  name="post_code"
                  type="text"
                  handleChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={t("post_code_placeholder")}
                  value={values.post_code}
                  error={isSubmitting ? "" : t(`${errors.post_code}`)}
                  touched={touched.post_code}
                  disabled={
                    isEmpty(values?.country_code) ||
                    isEmpty(values?.phone_number) ||
                    errors.country_code !== undefined ||
                    errors.phone_number !== undefined
                  }
                />

                <div className="flex flex-col flex-1">
                  <CustomInputField
                    type="text"
                    name="city"
                    handleChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={t("City")}
                    value={values.city}
                    error={isSubmitting ? "" : t(`${errors.city}`)}
                    touched={touched.city}
                  />
                </div>
              </>
            )}

            <div>
              {errorsState.length > 0 && (
                <div className="mb-4">
                  <Alert severity="error">
                    {errorsState.map((errorText: string, index: number) => (
                      <p className="text-sm" key={index}>
                        {t(buildString(errorText?.toLowerCase()) || "")}
                      </p>
                    ))}
                  </Alert>
                </div>
              )}
            </div>

            <div className="flex mt-7 justify-between gap-3">
              <CustomButton onClick={handleBack} label="back" />
              <CustomButton
                classNames="min-h-[48px] flex-1 rounded-xl text-white capitalize text-sm font-bold bg-black"
                onClick={() =>
                  handlePhoneNumberForExistence(
                    values.country_code,
                    values.phone_number,
                    validateUserName,
                    values,
                    onSubmit,
                    setSubmitting
                  )
                }
                label="next"
                disabled={processing || !isValid || isSubmitting}
              />

              <SignUpOtpVerification
                formhandleChange={handleChange}
                loading={processing || isSubmitting}
                isOtpSent={isOtpSent}
                open={isOtpModalOpen}
                onClose={() => {
                  setisOtpModalOpen(false);
                  setisOtpSent(false);
                  setotp("");
                }}
                onSubmit={() => {
                  if (isOtpSent) {
                    validateOTP(handleSubmit, values);
                  } else {
                    sendOTP(values, false);
                  }
                }}
                onResend={() => {
                  sendOTP(values, true);
                }}
                otp={otp}
                setotp={setotp}
                submitText={isOtpSent ? "submit" : "send_otp"}
              />
            </div>

            <CustomModal
              showCloseButton={true}
              showHorizontalRuler={true}
              headerClasses="flex justify-between items-center mb-3.5"
              open={fakeUserNameWarningModalOpen}
              containerClasses={"h-full flex-center"}
              modalClasses={
                "bg-white flex flex-col rounded-xl w-[486px] max-sm:!w-full min-h-[369px] p-4"
              }
              titleClasses={"font-bold text-xl max-sm:text-xl"}
              title={t("alert")}
              content={t("fake_name_warning")}
              contentComponent={<Content />}
              onNext={() => {
                setisOtpModalOpen(true);
                setfakeUserNameWarningModalOpen(false);
              }}
              onBack={() => {
                setfakeUserNameWarningModalOpen(false);
              }}
              onClose={() => {
                setfakeUserNameWarningModalOpen(false);
              }}
              onNextTitle={"confirm"}
              onBackTitle={"edit"}
              buttonContainerClasses={"flex gap-3 mt-auto"}
              disabled={processing}
              nextBtnClasses={
                processing
                  ? "w-full min-h-[48px] flex-1 rounded-xl text-white capitalize text-sm font-bold bg-black opacity-50"
                  : "w-full min-h-[48px] flex-1 rounded-xl text-white capitalize text-sm font-bold bg-black"
              }
              backBtnClasses={
                processing
                  ? "w-full min-h-[48px] flex-1 rounded-xl text-white capitalize text-sm font-bold bg-[#9E9E9E] opacity-50"
                  : "w-full min-h-[48px] flex-1 rounded-xl text-white capitalize text-sm font-bold bg-[#9E9E9E]"
              }
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PersonalInfoScreen;
